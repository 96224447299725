import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import {
  EmailAuthProvider,
  PhoneAuthProvider,
  RecaptchaVerifier,
  User,
  reauthenticateWithCredential,
  updateEmail,
  updatePhoneNumber,
} from "firebase/auth"
import React, { useCallback, useEffect, useState } from "react"
import { auth, db as storage } from "../firebase"
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"
import { phoneNumberMatch, validatePhoneNumber } from "../utils"

import Cropper from "react-easy-crop"
import { IUser } from "./stores/IUser"
import { LoadingService } from "../LoadingService"
import PhoneInput from "react-phone-input-2"
import { PopupService } from "./PopupService"
import SwipeableViews from "react-swipeable-views"
import { getCroppedImg } from "../utils/cropImage"
import { observer } from "mobx-react-lite"
import { useAuthState } from "react-firebase-hooks/auth"
import { useTranslation } from "react-i18next"
import { userStore } from "./stores/userStore"
import { v4 as uuidv4 } from "uuid"

interface EditProfileProps {
  onCancel: () => void
  onSave: (profile: any) => void
}

const EditProfile: React.FC<EditProfileProps> = observer(({ onCancel, onSave }) => {
  const { t } = useTranslation()
  const { user, updateUser } = userStore

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const [updatedProfile, setUpdatedProfile] = useState(user)
  const [newImage, setNewImage] = useState<File | null>(null)
  const [imagePreview, setImagePreview] = useState(user?.profileImageUrl)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null)
  const [cropDialogOpen, setCropDialogOpen] = useState(false)
  const [tabValue, setTabValue] = useState(0)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setUpdatedProfile((prevState: any) => ({ ...prevState, [name]: value }))
  }

  const handleTabChange = (event: any, newValue: any) => {
    setTabValue(newValue)
  }

  const handleChangeIndex = (index: number) => {
    setTabValue(index)
  }

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setNewImage(e.target.files[0])
      setImagePreview(URL.createObjectURL(e.target.files[0]))
      setCropDialogOpen(true)
    }
  }

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleSave = async () => {
    if (newImage && croppedAreaPixels) {
      try {
        const croppedImage = await getCroppedImg(imagePreview || "", croppedAreaPixels, zoom)
        const response = await fetch(croppedImage as string)
        const blob = await response.blob()
        const uniqueId = uuidv4()
        const file = new File([blob], `${uniqueId}-${newImage.name}`, { type: "image/jpeg" })

        const storageRef = ref(storage as any, `profileImages/${file.name}`)
        const uploadTask = uploadBytesResumable(storageRef, file)

        uploadTask.on(
          "state_changed",
          () => {},
          (error) => {
            console.error(error)
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
            await updateUser({ ...updatedProfile, profileImageUrl: downloadURL })
          },
        )
      } catch (error) {
        console.error("Error cropping image:", error)
      }
    } else {
      await updateUser(updatedProfile)
    }
    onSave && onSave(updatedProfile)
  }

  const handleCropDialogClose = () => {
    setCropDialogOpen(false)
  }

  const handleCropDialogSave = async () => {
    if (croppedAreaPixels) {
      try {
        const croppedImage = await getCroppedImg(imagePreview || "", croppedAreaPixels, zoom)
        setImagePreview(croppedImage as string)
        setCropDialogOpen(false)
      } catch (error) {
        console.error("Error saving cropped image:", error)
      }
    } else {
      console.error("Cropped area pixels not set")
    }
  }

  return (
    <div>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab label="Profile" {...a11yProps(0)} />
        <Tab label="Account" {...a11yProps(1)} />
        {/* <Tab label="Business Offers" {...a11yProps(2)} /> */}
      </Tabs>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={tabValue}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={tabValue} index={0} dir={theme.direction}>
          <ProfileInfo
            t={t}
            imagePreview={imagePreview}
            isSmallScreen={isSmallScreen}
            handleImageChange={handleImageChange}
            updatedProfile={updatedProfile}
            handleChange={handleChange}
            onCancel={onCancel}
            handleSave={handleSave}
            cropDialogOpen={cropDialogOpen}
            handleCropDialogClose={handleCropDialogClose}
            crop={crop}
            zoom={zoom}
            setCrop={setCrop}
            setZoom={setZoom}
            onCropComplete={onCropComplete}
            handleCropDialogSave={handleCropDialogSave}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1} dir={theme.direction}>
          <AccountInfo
            t={t}
            imagePreview={imagePreview}
            isSmallScreen={isSmallScreen}
            handleImageChange={handleImageChange}
            updatedProfile={updatedProfile}
            handleChange={handleChange}
            onCancel={onCancel}
            handleSave={handleSave}
            cropDialogOpen={cropDialogOpen}
            handleCropDialogClose={handleCropDialogClose}
            crop={crop}
            zoom={zoom}
            setCrop={setCrop}
            setZoom={setZoom}
            onCropComplete={onCropComplete}
            handleCropDialogSave={handleCropDialogSave}
            updateUser={updateUser}
          />
        </TabPanel>
        {/* <TabPanel value={tabValue} index={2} dir={theme.direction}>
          <BusinessOffersComponent businessId={id} />
        </TabPanel> */}
      </SwipeableViews>
    </div>
  )
})

export default EditProfile

function ProfileInfo(props: {
  t: any
  imagePreview: string | undefined
  isSmallScreen: boolean
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  updatedProfile: IUser | null | undefined
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onCancel: () => void
  handleSave: () => Promise<void>
  cropDialogOpen: boolean
  handleCropDialogClose: () => void
  crop: { x: number; y: number }
  zoom: number
  setCrop: React.Dispatch<React.SetStateAction<{ x: number; y: number }>>
  setZoom: React.Dispatch<React.SetStateAction<number>>
  onCropComplete: (croppedArea: any, croppedAreaPixels: any) => void
  handleCropDialogSave: () => Promise<void>
}) {
  const {
    t,
    imagePreview,
    isSmallScreen,
    handleImageChange,
    updatedProfile,
    handleChange,
    onCancel,
    handleSave,
    cropDialogOpen,
    handleCropDialogClose,
    crop,
    zoom,
    setCrop,
    setZoom,
    onCropComplete,
    handleCropDialogSave,
  } = props

  return (
    <Card sx={{ maxWidth: 345, mx: "auto", mt: 4 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {t("profile.editProfile")}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <Avatar
            variant="circular"
            src={imagePreview}
            style={{
              width: isSmallScreen ? 100 : 140,
              height: isSmallScreen ? 100 : 140,
              borderRadius: "50%",
            }}
          />
          {/* <img
          src={imagePreview}
          alt="Profile Preview"
          style={{
            width: isSmallScreen ? 100 : 140,
            height: isSmallScreen ? 100 : 140,
            borderRadius: "50%",
          }}
        /> */}
        </Box>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: "none" }}
          id="profile-image-upload"
        />
        <label htmlFor="profile-image-upload">
          <Button variant="outlined" color="primary" component="span">
            {t("profile.uploadNewImage")}
          </Button>
        </label>
        <TextField
          margin="normal"
          fullWidth
          label={t("profile.name")}
          name="name"
          value={updatedProfile?.name}
          onChange={handleChange}
        />
        <TextField
          disabled
          margin="normal"
          fullWidth
          label={t("profile.email")}
          name="email"
          value={updatedProfile?.email}
          onChange={handleChange}
        />
        <TextField
          disabled
          margin="normal"
          fullWidth
          label={t("profile.phoneNumber")}
          name="phoneNumber"
          value={updatedProfile?.phoneNumber}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          fullWidth
          label={t("profile.country")}
          name="country"
          value={updatedProfile?.country}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          fullWidth
          label={t("profile.language")}
          name="language"
          value={updatedProfile?.language}
          onChange={handleChange}
        />
      </CardContent>
      <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
        <Button variant="contained" color="secondary" onClick={onCancel}>
          {t("profile.cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          {t("profile.save")}
        </Button>
      </Box>

      <Dialog open={cropDialogOpen} onClose={handleCropDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>{t("profile.cropImage")}</DialogTitle>
        <DialogContent>
          <Box sx={{ position: "relative", width: "100%", height: 400, backgroundColor: "#333" }}>
            <Cropper
              image={imagePreview}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCropDialogClose}>{t("profile.cancel")}</Button>
          <Button onClick={handleCropDialogSave} variant="contained" color="primary">
            {t("profile.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

function AccountInfo(props: {
  t: any
  imagePreview: string | undefined
  isSmallScreen: boolean
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  updatedProfile: IUser | null | undefined
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onCancel: () => void
  handleSave: () => Promise<void>
  cropDialogOpen: boolean
  handleCropDialogClose: () => void
  crop: { x: number; y: number }
  zoom: number
  setCrop: React.Dispatch<React.SetStateAction<{ x: number; y: number }>>
  setZoom: React.Dispatch<React.SetStateAction<number>>
  onCropComplete: (croppedArea: any, croppedAreaPixels: any) => void
  handleCropDialogSave: () => Promise<void>
  updateUser: any
}) {
  const { user: profile } = userStore
  const [user]: any[] = useAuthState(auth)
  const [verificationId, setVerificationId] = useState<string>("")
  const { t, updatedProfile, handleChange, onCancel, updateUser } = props
  const [verificationCode, setVerificationCode] = useState("")
  const [phoneNumber, setPhoneNumber] = useState<string>(updatedProfile?.phoneNumber || "")
  const [newValidPhoneNumber, setNewValidPhoneNumber] = useState(false)
  const [countryCode, setCountryCode] = useState("")
  const [hasPhoneProvider, setHasPhoneProvider] = useState(false)
  const [newEmail, setNewEmail] = useState(updatedProfile?.email || "")

  const [emailVerified, setEmailVerified] = useState(false)

  // Step 1: Send verification email to new email address
  const sendVerificationEmail = async (email: string) => {
    try {
      const verificationMessage = await user.sendEmailVerification()
      return verificationMessage
    } catch (error: any) {
      PopupService.pushPopup(`Failed to send verification email: ${error.message}`)
      return null
    }
  }

  useEffect(() => {
    const result = user?.providerData?.find((x: any) => x.providerId === "phone")
    // console.log("user?.providerData----->", user?.providerData)
    setHasPhoneProvider(!!result)
  }, [user])

  useEffect(() => {
    // console.log("phoneNumber---->", phoneNumber)
    // console.log("updatedProfile?.phoneNumber---->", updatedProfile?.phoneNumber)
    const result = !!phoneNumber
      ? validatePhoneNumber(phoneNumber, countryCode.toUpperCase() as any)
      : null
    const phonesMatch = phoneNumberMatch(phoneNumber, updatedProfile?.phoneNumber || "").match
    setNewValidPhoneNumber(!!result?.valid && !phonesMatch)
  }, [phoneNumber, countryCode, updatedProfile?.phoneNumber])

  const handleUpdateEmail = async () => {
    if (updatedProfile?.email) {
      /*
      Updates the user's email address.

@remarks
An email will be sent to the original email address (if it was set) that allows to revoke the email address change, in order to protect them from account hijacking.

This method is not supported on any User signed in by Auth instances created with a @firebase/app#FirebaseServerApp.

Important: this is a security sensitive operation that requires the user to have recently signed in. If this requirement isn't met, ask the user to authenticate again and then call reauthenticateWithCredential.
      */
      const user: User = auth.currentUser as any

      try {
        LoadingService.setLoading(true)
        if (user) {
          if (!user.emailVerified) {
            PopupService.pushPopup("Please verify your email first.")
            await sendVerificationEmail(newEmail)
            PopupService.pushPopup("Verification email sent! Please check your inbox.")
          } else {
            await updateEmail(user, newEmail)
            PopupService.pushPopup("Email updated successfully!")
            setNewEmail("")
          }
        } else {
          PopupService.pushPopup("No user is currently signed in.")
        }
      } catch (error: any) {
        PopupService.pushPopup(`Failed to update email: ${error.message}`)
      } finally {
        LoadingService.setLoading(false)
      }

      try {
        await updateEmail(user, updatedProfile?.email)
      } catch (error: any) {
        PopupService.pushPopup(error.message)
      }
    }
    await updateUser(updatedProfile)
  }

  // const reauthenticate = async (password: string) => {
  //   const user: User = auth.currentUser as any
  //   const credential = EmailAuthProvider.credential(user.email, password)
  //   try {
  //     await reauthenticateWithCredential(user, credential)
  //   } catch (error: any) {
  //     PopupService.pushPopup(`Reauthentication failed: ${error.message}`)
  //   }
  // }

  const handleSavePhoneNumber = async () => {
    try {
      LoadingService.setLoading(true)
      const data = {
        phoneNumber,
      }
      updateUser(data)

      handleChange({
        target: {
          name: "phoneNumber",
          value: phoneNumber,
        },
      } as any)
    } catch (error: any) {
      PopupService.setPopup(error.message)
    } finally {
      LoadingService.setLoading(false)
    }
  }

  const validatePhoneCode = async () => {
    // Obtain the verificationCode from the user.
    const phoneCredential = PhoneAuthProvider.credential(verificationId, verificationCode)
    await updatePhoneNumber(user, phoneCredential)
  }

  const handleValidatePhoneNumber = async () => {
    // 'recaptcha-container' is the ID of an element in the DOM.
    const applicationVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "normal", // or 'invisible' based on your needs
      callback: (response: any) => {
        // reCAPTCHA solved - allow the user to send the OTP
        console.log("reCAPTCHA solved", response)
      },
      "expired-callback": () => {
        // Handle expiration
        console.log("reCAPTCHA expired")
      },
    })
    const provider = new PhoneAuthProvider(auth)
    const id = await provider.verifyPhoneNumber(phoneNumber, applicationVerifier)
    setVerificationId(id)
  }

  return (
    <Card sx={{ maxWidth: 345, mx: "auto", mt: 4 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {t("editAccount")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            m: 2,
          }}
        >
          <TextField
            margin="normal"
            fullWidth
            label={t("profile.email")}
            name="email"
            value={updatedProfile?.email}
            onChange={({ target }) => setNewEmail(target.value)}
            slotProps={{
              input: {
                endAdornment: (
                  <Button variant="contained" color="primary" onClick={handleUpdateEmail}>
                    {t("profile.save")}
                  </Button>
                ),
              },
            }}
          />
        </Box>

        {verificationId ? (
          <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
            <TextField
              margin="normal"
              fullWidth
              label={t("verificationCode")}
              name="verificationCode"
              value={verificationCode}
              onChange={({ target }) => setVerificationCode(target.value)}
              slotProps={{
                input: {
                  startAdornment: (
                    <Button variant="contained" color="primary" onClick={validatePhoneCode}>
                      {t("send")}
                    </Button>
                  ),
                },
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              border: "1px solid #CACACA",
              borderRadius: 2,
              padding: 1,
              m: 2,
            }}
          >
            <PhoneInput
              specialLabel="Phone"
              inputProps={{
                id: "recaptcha-container",
              }}
              autocompleteSearch
              // alwaysDefaultMask
              // showDropdown
              preferredCountries={["US", "DO"]}
              country={"us"}
              value={phoneNumber}
              onChange={(phone, option: any, e, format) => {
                setCountryCode(option.countryCode)
                // console.log("PhoneInput---->", { phone, option, e, format })
                setPhoneNumber(format)
              }}
              // inputStyle={{ width: "100%" }}
              // containerStyle={{ width: "100%", marginTop: 16 }}
            />
            {!newValidPhoneNumber ? null : hasPhoneProvider ? (
              <Button variant="contained" color="primary" onClick={handleValidatePhoneNumber}>
                {t("profile.validatePhone")}
              </Button>
            ) : (
              <Button variant="contained" color="primary" onClick={handleSavePhoneNumber}>
                {t("profile.save")}
              </Button>
            )}

            {/* <TextField
              id="recaptcha-container"
              margin="normal"
              fullWidth
              label={t("profile.phoneNumber")}
              name="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              slotProps={{
                input: {
                  endAdornment: user?.providerData?.find((x: any) => x.providerId === "phone") ? (
                    <Button variant="contained" color="primary" onClick={handleSavePhoneNumber}>
                      {t("profile.ok")}
                    </Button>
                  ) : newValidPhoneNumber ? (
                    <Button variant="contained" color="primary" onClick={handleValidatePhoneNumber}>
                      {t("profile.validatePhone")}
                    </Button>
                  ) : null,
                },
              }}
            /> */}
          </Box>
        )}
      </CardContent>
      <Box sx={{ display: "flex", justifyContent: "end", p: 2 }}>
        <Button variant="contained" color="secondary" onClick={onCancel}>
          {t("profile.cancel")}
        </Button>
      </Box>
    </Card>
  )
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `profile-tab-${index}`,
    "aria-controls": `profile-tabpanel-${index}`,
  }
}

import { DateTime, Settings } from "luxon"
import { LocationType, getLocationQuery } from "../../utils"
import {
  Timestamp,
  and,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore"
import { User, signOut } from "firebase/auth"
import { auth, db as firestore, observeAuthState } from "../../firebase"
import { makeAutoObservable, runInAction } from "mobx"

import { ISponsor } from "./Sponsor"
import { ISupportInfo } from "./ISupportInfo"
import { IUser } from "./IUser"
import { LoadingService } from "../../LoadingService"
// import { OffersConfig } from "./OffersConfig"
import { PopupService } from "../PopupService"
import { createTheme } from "@mui/material"

const colors = {
  primary: {
    main: "#04975C",
  },
  secondary: {
    main: "#edf2ff",
  },
}
const darkTheme = createTheme({
  palette: {
    mode: "dark", //"light", //
    ...colors,
  },
})

const lightTheme = createTheme({
  palette: {
    mode: "light", //
    ...colors,
  },
})
class UserStore {
  points: number = 0
  usersCount: number = 0
  ticketsCount: number = 0
  usersCountIcon: any = {}
  supportInfo: ISupportInfo = {}

  user: IUser | null | undefined = null
  loading: boolean = true
  isLoggedIn: boolean = false
  sponsors: any[] = []
  displayContentConfig: any = {}
  theme: any = lightTheme
  mode: string = "light"

  constructor() {
    makeAutoObservable(this)
    this.useDarkTheme = this.useDarkTheme.bind(this)
    this.useLightTheme = this.useLightTheme.bind(this)
    this.loadUser = this.loadUser.bind(this)
    this.updateUser = this.updateUser.bind(this)
    this.deleteUser = this.deleteUser.bind(this)
    this.setPoints = this.setPoints.bind(this)
    this.addSponsor = this.addSponsor.bind(this)
    this.listenForSponsorsChanges = this.listenForSponsorsChanges.bind(this)
    this.resetSponsor = this.resetSponsor.bind(this)

    observeAuthState(async (user: User) => {
      if (user) {
        this.isLoggedIn = !user.isAnonymous

        await this.getUsersStats()
        this.supportInfo = await this.getSupportInfo()
        this.displayContentConfig = await this.getDisplayContentConfig()
        if (this.isLoggedIn) {
          await this.loadUser(user.uid)
          // this.ticketsCount = await this.getUsersTickets()
          this.updateUserTickets(user.uid)
        }
      } else {
        runInAction(() => {
          this.user = null
          this.loading = false
        })
      }
    })
  }

  useDarkTheme() {
    this.theme = darkTheme
    this.mode = "dark"
  }

  useLightTheme() {
    this.theme = lightTheme
    this.mode = "light"
  }

  updateUserTickets(id: string) {
    const querySnapshot = collection(firestore, "users", id, "tickets")

    const result = query(
      querySnapshot,
      and(
        where("expirationDate", ">=", Timestamp.now().toMillis()),
        where("disabled", "==", false),
      ),
    )
    return onSnapshot(result, {
      next: (snapshot) => {
        this.ticketsCount = snapshot.size
      },
      error: (error) => console.error("There was an error getting updateUserTickets-->", error),
    })
  }

  resetSponsor() {
    this.sponsors = []
  }
  addSponsor(data: ISponsor, reset: boolean = false) {
    if (data) {
      if (reset || !this.sponsors) {
        this.sponsors = []
      }
      this.sponsors.push(data)
    }
  }

  getSponsorRef(location: LocationType) {
    const arr = getLocationQuery(location, false)
    //query(collection(db, `sponsors/${sponsorId}/services`), and(...arr))
    return query(collection(firestore, "sponsors"), and(...arr), orderBy("order"))
  }

  listenForSponsorsChanges(location: LocationType, get?: Function) {
    return onSnapshot(this.getSponsorRef(location), {
      next: (snapshot) => {
        this.resetSponsor()
        if (get) {
          get(snapshot)
        } else {
          snapshot.forEach((item: any) => {
            const resultData: ISponsor = item.data()
            resultData.image = resultData.image || ""
            this.addSponsor(resultData)
          })
        }
      },
      error: (error) => {
        this.resetSponsor()
        console.error("There was an error getting ListenForSponsorsChanges-->", error)
      },
    })
  }

  private getSponsorDoc(id?: string) {
    const ref = collection(firestore, "sponsors")
    return id ? doc(ref, id) : doc(ref)
  }

  setPoints(points: number) {
    this.points = points || 0
  }

  private async getDisplayContentConfig() {
    const querySnapshot = doc(firestore, "app-configurations/display-content-config")

    const ref = await getDoc(querySnapshot)
    const data: any = ref.data()

    return data
  }

  private async getSupportInfo() {
    const querySnapshot = doc(firestore, "app-configurations/technicalSupport")

    const ref = await getDoc(querySnapshot)
    const data: any = ref.data()

    return data
  }

  private async getUsersStats() {
    const querySnapshot = doc(firestore, "app-configurations/usersStats")

    const ref = await getDoc(querySnapshot)
    const data: any = ref.data()
    // this.usersCountIcon = data?.icon || {}
    this.usersCount = data?.count || 0
    // console.log("USERS STATS ----->", data)
    return data
  }

  private async getUsersTickets() {
    const id = this.user?.uid || this.user?.id

    if (!id) {
      return 0
    }

    try {
      const querySnapshot = collection(firestore, "users", id, "tickets")

      const result = await getCountFromServer(
        query(
          querySnapshot,
          and(
            where("expirationDate", ">=", Timestamp.now().toMillis()),
            where("disabled", "==", false),
          ),
        ),
      )

      return result?.data()?.count || 0
    } catch (error) {
      console.log("getUsersTickets----error---->", error)
      return 0
    }
  }

  async loadUser(uid?: string) {
    uid = uid || auth?.currentUser?.uid
    if (uid) {
      const userDoc = await getDoc(doc(firestore, "users", uid))
      runInAction(() => {
        this.user = userDoc.data() as any
        this.loading = false
        this.points = this.user?.points || 0
      })
    } else {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  async loadApp(uid?: string) {
    uid = uid || auth?.currentUser?.uid
    if (uid) {
      const userDoc = await getDoc(doc(firestore, "users", uid))
      runInAction(() => {
        this.user = userDoc.data() as any
        this.loading = false
      })
    } else {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  async setUserData(updatedUser: any) {
    const user = auth.currentUser
    if (user) {
      await setDoc(doc(firestore, "users", user.uid), updatedUser)
      runInAction(() => {
        this.user = updatedUser
      })
    }
  }

  async updateUser(updatedUser: any) {
    const user = auth.currentUser
    if (user) {
      await updateDoc(doc(firestore, "users", user.uid), updatedUser)
      runInAction(() => {
        this.user = { ...this.user, ...updatedUser }
      })
    }
  }

  async deleteUser() {
    const user = auth.currentUser
    if (user) {
      await deleteDoc(doc(firestore, "users", user.uid))
      await user.delete()
      runInAction(() => {
        this.user = null
      })
    }
  }

  async handleLogout() {
    LoadingService.setLoading(true)
    try {
      await signOut(auth)
    } catch (error: any) {
      PopupService.pushPopup(error?.message || error, "Error")
    } finally {
      LoadingService.setLoading(false)
    }
  }

  async getThisMonthOffers(sponsorId: string): Promise<any> {
    try {
      const currentMonth = DateTime.now().toFormat("LLyyyy")
      // console.log('getThisMonthOffers ----currentMonth------->', currentMonth)
      // console.log('getThisMonthOffers ----date------->', date)
      // console.log('getThisMonthOffers ----sponsorId------->', sponsorId)
      // console.log('currentMonth---->', currentMonth);
      const ref = query(
        collection(firestore, "sponsors", sponsorId, "offers", currentMonth, "offers"),
        orderBy("order"),
        where("disabled", "==", false),
      )
      ///sponsors/8IAQdwz85Qokfmifsd02/offers/042024/offers
      // const found = self.sponsors.find(x => x.id === id);
      // const data = result.data()
      const arr: any[] = []
      const docsRef = await getDocs(ref)
      docsRef.forEach((element) => {
        const data = element.data()
        // console.log("DATA---->", data)
        arr.push(data)
      })
      return arr
    } catch (error) {
      console.log("There was an error trying to load sponsors offers", error)
    }
  }
}

/* getThisMonthOffers(sponsorId: string) {
    try {
      const currentMonth = DateTime.now().toFormat("LLyyyy")
      // console.log('getThisMonthOffers ----currentMonth------->', currentMonth)
      // console.log('getThisMonthOffers ----date------->', date)
      // console.log('getThisMonthOffers ----sponsorId------->', sponsorId)
      // console.log('currentMonth---->', currentMonth);
      const ref = query(
        collection(firestore, "sponsors", sponsorId, currentMonth, "offers"),
        orderBy("order"),
        where("disabled", "==", false),
      )

      // const found = self.sponsors.find(x => x.id === id);
      // const data = result.data()
      const arr = []
      result.forEach((element) => {
        const data = element.data()
        // console.log("DATA---->", data)
        arr.push(data)
      })

      if (!date) {
        self.thisMonthOffers = cast(arr)
      }

      return arr
    } catch (error) {
      console.log("There was an error trying to load sponsors offers", error)
    } finally {
      self.loading = false
    }
  }
*/

export const userStore = new UserStore()

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Grid2,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"

import EditIcon from "@mui/icons-material/Edit"
import React from "react"
import { UserPoints } from "./UserPoints"
import { auth } from "../firebase"
import { getLocationStr } from "../utils"
import { observer } from "mobx-react-lite"
import { signOut } from "firebase/auth"
import { useTranslation } from "react-i18next"
import { userStore } from "./stores/userStore"

interface ProfileProps {
  onEdit: () => void
}

const ViewProfile: React.FC<ProfileProps> = observer(({ onEdit }) => {
  const { user } = userStore
  const { t } = useTranslation()

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // console.log("User logged out")
        window.location.reload()
      })
      .catch((error) => {
        console.error("Error logging out: ", error)
      })
  }

  const handleDeleteAccount = async () => {
    await userStore.deleteUser()
  }

  if (!user) {
    return <div>{t("loading")}...</div>
  }
  const Row = ({ label, value, type }: any) => (
    <Grid2 container spacing={2}>
      <Grid2>
        <strong>{t(label)}:</strong>
      </Grid2>
      <Grid2>{value}</Grid2>
    </Grid2>
  )
  return (
    <Card>
      <Box className="flex justify-center mt-2 position-relative">
        <Button
          onClick={onEdit}
          style={{
            position: "absolute",
            left: "84%",
          }}
        >
          <EditIcon />
        </Button>
        <Avatar
          variant="circular"
          // component="img"
          src={user.profileImageUrl}
          alt={t("Profile")}
          sx={{
            width: isSmallScreen ? 100 : 140,
            height: isSmallScreen ? 100 : 140,
            borderRadius: "50%",
          }}
        />
      </Box>
      <UserPoints complete variant="text" className="w-full flex-wrap p-1" />
      <CardContent className="flex flex-col justify-center">
        <div className="m-5">
          <Typography gutterBottom variant="h5" component="div" className="flex justify-center">
            {user.name}
          </Typography>
          <Typography variant="h6" color="text.secondary" className="flex justify-center">
            {user.email}
          </Typography>
        </div>

        <Grid2 container spacing={2} direction="column">
          <Row label="phone" value={user.phoneNumber} />
          <Row label="address" value={getLocationStr(user.userAddress)} />
          <Row label="language" value={user.language} />
          <Row label="location" value={user.location} />
          <Row label="pushNotification" value={user.pushNotifications} type="boolean" />
          <Row label="currency" value={user.currency} />
          {/* <Grid item xs={6}>
    <Item>xs=6</Item>
  </Grid>
  <Grid item xs>
    <Item>xs</Item>
  </Grid> */}
        </Grid2>
        {/* 
        <Typography variant="h6" color="text.secondary">
          <strong>{t("phone")}:</strong> {user.phoneNumber}
        </Typography>

        <Typography variant="body2" color="text.secondary">
          <strong>{t("address")}:</strong> {getLocationStr(user.userAddress)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>{t("language")}:</strong> {user.language}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>{t("location")}:</strong> {user.location}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>{t("pushNotification")}:</strong> {user.pushNotifications}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>{t("currency")}:</strong> {user.currency}
        </Typography> */}
      </CardContent>
      {/* <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
        <Button variant="contained" color="primary" onClick={onEdit}>
          {t("profile.editProfile")}
        </Button>
      </Box> */}
      <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }} className="gap-2">
        <Button variant="text" color="error" onClick={handleDeleteAccount}>
          {t("profile.deleteAccount")}
        </Button>

        <Button variant="text" color="warning" onClick={handleLogout}>
          {t("profile.logout")}
        </Button>
      </Box>
    </Card>
  )
})

export default ViewProfile

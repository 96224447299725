import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import React, { useEffect, useState } from "react"
import { doc, getDoc } from "firebase/firestore"

import { db } from "./firebase"
import { useTranslation } from "react-i18next"

const LanguageSelector: React.FC = () => {
  const { i18n, t } = useTranslation()
  const currentLanguage = i18n.language || "en"
  const [availableLangs, setAvailableLangs] = useState([
    { code: "en", value: "English" },
    { code: "es", value: "Spanish" },
  ])
  const [lang, setLang] = useState("en")

  useEffect(() => {
    async function getLangs() {
      const querySnapshot = doc(db, "app-configurations/languages")

      const docRef = await getDoc(querySnapshot)

      const kv: any = docRef.data()

      setAvailableLangs(
        Object.keys(kv).map((k) => kv[k]),
      )
    }
    
    getLangs();

    if (/.+-.+/.test(currentLanguage)) {
      const result = /(.+)-.+/.exec(currentLanguage)?.[1] || "en"
      setLang(result)
    } else {
      setLang(currentLanguage)
    }
  }, [currentLanguage])

  const handleLanguageChange = (event: any) => {
    const selectedLanguage = (event.target.value as string) || "en"
    i18n.changeLanguage(selectedLanguage)
  }

  return (
    <Box sx={{ minWidth: 120, marginTop: 2, width: '100%' }}>
      <FormControl fullWidth>
        <InputLabel id="language-selector-label">{t("profile.language")}</InputLabel>
        <Select
          className="flex flex-column gap-10"
          label={t("profile.language")}
          labelId="language-selector-label"
          value={lang}
          onChange={handleLanguageChange}
        >
          {availableLangs.map(({ code, value }) => (
            <MenuItem value={code}>{t(value)}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
/*
  <FormControl fullWidth>
            <InputLabel>Country</InputLabel>
            <Select
              className="flex flex-column gap-10"
              value={selectedCountry}
              label="Country"
              onChange={(val) => {
                const country: any = val?.target?.value || "US"
                setSelectedCountry(country)
                setUserSelectedFilter((val: any) => ({ ...val, country }))
              }}
            >
              {Object.keys(availableLocations).map((country: any, i: number) => (
                <MenuItem key={`${country}-${i}`} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
*/
export default LanguageSelector
